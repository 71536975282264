// extracted by mini-css-extract-plugin
export var arH1Mobile = "ArPlatformProductsTilesMenu-module--ar-h1-mobile--3Ly-b";
export var arH2Mobile = "ArPlatformProductsTilesMenu-module--ar-h2-mobile--PJ0IZ";
export var arH3Mobile = "ArPlatformProductsTilesMenu-module--ar-h3-mobile--2o72y";
export var arH4Mobile = "ArPlatformProductsTilesMenu-module--ar-h4-mobile--2lj7t";
export var arH5Mobile = "ArPlatformProductsTilesMenu-module--ar-h5-mobile--1Gxsu";
export var arH1 = "ArPlatformProductsTilesMenu-module--ar-h1--S8OOE";
export var arH2 = "ArPlatformProductsTilesMenu-module--ar-h2--Zs3Ha";
export var arH3 = "ArPlatformProductsTilesMenu-module--ar-h3--3THjG";
export var arH4 = "ArPlatformProductsTilesMenu-module--ar-h4--10OZT";
export var arH5 = "ArPlatformProductsTilesMenu-module--ar-h5--26neR";
export var arParagraphXl = "ArPlatformProductsTilesMenu-module--ar-paragraph-xl--2wDpn";
export var arParagraphXlMedium = "ArPlatformProductsTilesMenu-module--ar-paragraph-xl-medium--2piUv";
export var arParagraphBase = "ArPlatformProductsTilesMenu-module--ar-paragraph-base--1EhCH";
export var arParagraphBaseMedium = "ArPlatformProductsTilesMenu-module--ar-paragraph-base-medium--ee-9v";
export var arParagraphSmall = "ArPlatformProductsTilesMenu-module--ar-paragraph-small--IZwy8";
export var arParagraphSmallMedium = "ArPlatformProductsTilesMenu-module--ar-paragraph-small-medium--1T98q";
export var arParagraphXsmall = "ArPlatformProductsTilesMenu-module--ar-paragraph-xsmall--3Z9OM";
export var arParagraphXsmallMedium = "ArPlatformProductsTilesMenu-module--ar-paragraph-xsmall-medium--grlK5";
export var arLinkMobile = "ArPlatformProductsTilesMenu-module--ar-link-mobile--3e_8U";
export var arLinkDesktop = "ArPlatformProductsTilesMenu-module--ar-link-desktop--2jVJ-";
export var arQuote = "ArPlatformProductsTilesMenu-module--ar-quote--SOd4K";
export var arQuoteMobile = "ArPlatformProductsTilesMenu-module--ar-quote-mobile--3q1gp";
export var arGradientLight = "ArPlatformProductsTilesMenu-module--ar-gradient-light--10qW5";
export var arGradientBlue = "ArPlatformProductsTilesMenu-module--ar-gradient-blue--1CaIm";
export var arTransition = "ArPlatformProductsTilesMenu-module--ar-transition--1-mm9";
export var container = "ArPlatformProductsTilesMenu-module--container--38xx6";
export var scrollWrapper = "ArPlatformProductsTilesMenu-module--scrollWrapper--cVvil";
export var iconTile = "ArPlatformProductsTilesMenu-module--iconTile--GdXW5";
export var actionButton = "ArPlatformProductsTilesMenu-module--actionButton--1MHuX";
export var show = "ArPlatformProductsTilesMenu-module--show--XTcei";