// extracted by mini-css-extract-plugin
export var arH1Mobile = "ArExtraFeatures-module--ar-h1-mobile--l0G0h";
export var arH2Mobile = "ArExtraFeatures-module--ar-h2-mobile--2cJ4x";
export var arH3Mobile = "ArExtraFeatures-module--ar-h3-mobile--3UJ26";
export var arH4Mobile = "ArExtraFeatures-module--ar-h4-mobile--1QGso";
export var arH5Mobile = "ArExtraFeatures-module--ar-h5-mobile--PwCB5";
export var arH1 = "ArExtraFeatures-module--ar-h1--32peS";
export var arH2 = "ArExtraFeatures-module--ar-h2--3Xila";
export var arH3 = "ArExtraFeatures-module--ar-h3--3Kx7k";
export var arH4 = "ArExtraFeatures-module--ar-h4--2V2ir";
export var arH5 = "ArExtraFeatures-module--ar-h5--24BTJ";
export var arParagraphXl = "ArExtraFeatures-module--ar-paragraph-xl--M6Ju6";
export var arParagraphXlMedium = "ArExtraFeatures-module--ar-paragraph-xl-medium--9eF7H";
export var arParagraphBase = "ArExtraFeatures-module--ar-paragraph-base--3J2fd";
export var arParagraphBaseMedium = "ArExtraFeatures-module--ar-paragraph-base-medium--3J7sd";
export var arParagraphSmall = "ArExtraFeatures-module--ar-paragraph-small--3X1t7";
export var arParagraphSmallMedium = "ArExtraFeatures-module--ar-paragraph-small-medium--1ImvE";
export var arParagraphXsmall = "ArExtraFeatures-module--ar-paragraph-xsmall--3jNSF";
export var arParagraphXsmallMedium = "ArExtraFeatures-module--ar-paragraph-xsmall-medium--OAfN5";
export var arLinkMobile = "ArExtraFeatures-module--ar-link-mobile--17-j0";
export var arLinkDesktop = "ArExtraFeatures-module--ar-link-desktop--EiUmo";
export var arQuote = "ArExtraFeatures-module--ar-quote--1gr69";
export var arQuoteMobile = "ArExtraFeatures-module--ar-quote-mobile--3pQ2U";
export var arGradientLight = "ArExtraFeatures-module--ar-gradient-light--1D8MA";
export var arGradientBlue = "ArExtraFeatures-module--ar-gradient-blue--U34K_";
export var arTransition = "ArExtraFeatures-module--ar-transition--27PhC";
export var container = "ArExtraFeatures-module--container--3VnZX";
export var smallSpaceBetween = "ArExtraFeatures-module--smallSpaceBetween--1SEec";
export var tiles = "ArExtraFeatures-module--tiles--21bE_";