// extracted by mini-css-extract-plugin
export var arH1Mobile = "ArPlatformProducts-module--ar-h1-mobile--3UWft";
export var arH2Mobile = "ArPlatformProducts-module--ar-h2-mobile--3e15j";
export var arH3Mobile = "ArPlatformProducts-module--ar-h3-mobile--1k7YP";
export var arH4Mobile = "ArPlatformProducts-module--ar-h4-mobile--2208x";
export var arH5Mobile = "ArPlatformProducts-module--ar-h5-mobile--3JXdg";
export var arH1 = "ArPlatformProducts-module--ar-h1--lz3aI";
export var arH2 = "ArPlatformProducts-module--ar-h2--3O8xj";
export var arH3 = "ArPlatformProducts-module--ar-h3--LgL5b";
export var arH4 = "ArPlatformProducts-module--ar-h4--2fm57";
export var arH5 = "ArPlatformProducts-module--ar-h5--2zNrA";
export var arParagraphXl = "ArPlatformProducts-module--ar-paragraph-xl--51njn";
export var arParagraphXlMedium = "ArPlatformProducts-module--ar-paragraph-xl-medium--GDJ7P";
export var arParagraphBase = "ArPlatformProducts-module--ar-paragraph-base--3TotZ";
export var arParagraphBaseMedium = "ArPlatformProducts-module--ar-paragraph-base-medium--mwV7m";
export var arParagraphSmall = "ArPlatformProducts-module--ar-paragraph-small--2Rcls";
export var arParagraphSmallMedium = "ArPlatformProducts-module--ar-paragraph-small-medium--6snyU";
export var arParagraphXsmall = "ArPlatformProducts-module--ar-paragraph-xsmall--1hq8X";
export var arParagraphXsmallMedium = "ArPlatformProducts-module--ar-paragraph-xsmall-medium--2hjTo";
export var arLinkMobile = "ArPlatformProducts-module--ar-link-mobile--1SCGZ";
export var arLinkDesktop = "ArPlatformProducts-module--ar-link-desktop--2wu8N";
export var arQuote = "ArPlatformProducts-module--ar-quote--1sBBl";
export var arQuoteMobile = "ArPlatformProducts-module--ar-quote-mobile--2c0gC";
export var arGradientLight = "ArPlatformProducts-module--ar-gradient-light--GnTrV";
export var arGradientBlue = "ArPlatformProducts-module--ar-gradient-blue--n-CIh";
export var arTransition = "ArPlatformProducts-module--ar-transition--3-KBX";
export var container = "ArPlatformProducts-module--container--37p2X";
export var textWithImage = "ArPlatformProducts-module--textWithImage--3uHI9";
export var textOnTheLeft = "ArPlatformProducts-module--textOnTheLeft--iChaj";
export var image = "ArPlatformProducts-module--image--30sw3";
export var content = "ArPlatformProducts-module--content--3gM81";
export var tag = "ArPlatformProducts-module--tag--YstEg";
export var algorithmicUnderwriting = "ArPlatformProducts-module--algorithmicUnderwriting--1ggVs";
export var contentWrapper = "ArPlatformProducts-module--contentWrapper--1dyUi";
export var algorithmicUnderwritingColumns = "ArPlatformProducts-module--algorithmicUnderwritingColumns--3O7aD";
export var dataIngestion = "ArPlatformProducts-module--dataIngestion--1F_Z1";
export var instantRiskTraging = "ArPlatformProducts-module--instantRiskTraging--3gNDS";
export var contractBuilder = "ArPlatformProducts-module--contractBuilder--2-12V";
export var underwritingWorkbench = "ArPlatformProducts-module--underwritingWorkbench--3GZkC";
export var integrationsAndApi = "ArPlatformProducts-module--integrationsAndApi--2JiTo";